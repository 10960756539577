import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import { App } from './components/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-spa-sso-lookup',
  rootComponent: App,
  sentry: {
    publicKey: '3ad2433d5aa44d0eadbb1820b9d7ae0f@o37442',
    projectId: '4505149269999616',
    releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  },
  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'spa-sso-lookup'
