import React, { useMemo } from 'react'
import { Formik, Form } from 'formik'
import { object, string } from 'yup'
import { useSentry } from 'banquet-runtime-modules'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { ArrowBackIcon } from '@toasttab/buffet-pui-icons'
import { TextInputField, SubmitButton } from '@toasttab/buffet-pui-forms'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'

import { LOOKUP_ENDPOINT } from '../utils/constant'
import { useTranslation } from '@local/translations'

export interface LookupFormProps {
  onCancel: () => void
  onSuccess: (data: OrganizationData) => void
}

export const LookupForm: React.FC<LookupFormProps> = ({
  onCancel,
  onSuccess
}) => {
  const { t, schema, fetchOrganization } = useLookupForm()

  return (
    <CardContainer
      testId='spa-sso-lookup-form-section'
      noPadding
      className='flex flex-col justify-center items-center w-96'
    >
      <Formik
        initialValues={{ organization: '' }}
        validationSchema={schema}
        onSubmit={async (values, form) => {
          const { error, data } = await fetchOrganization(values.organization)

          if (error) {
            form.setFieldError('organization', error)
          }

          if (data) {
            onSuccess(data)
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <div className='flex flex-col w-full px-7 mt-9 mb-4'>
              <div className='flex items-center self-start mb-3'>
                <IconButton
                  disabled={isSubmitting}
                  icon={
                    <ArrowBackIcon aria-label={t('lookup-form.cancel.label')} />
                  }
                  onClick={onCancel}
                />
                <h1 id='spa-sso-lookup-form-title' className='type-headline-5'>
                  {t('lookup-form.title')}
                </h1>
              </div>

              <Form data-testid='sso-lookup-organization-form'>
                <h2 className='type-caption'>
                  {t('lookup-form.organization.label')}
                </h2>
                <TextInputField
                  containerClassName='flex-grow mb-14 mt-4'
                  name='organization'
                  disabled={isSubmitting}
                  placeholder={t('lookup-form.organization.placeholder')}
                />
                <div className='flex space-x-4'>
                  <Button
                    variant='link'
                    type='reset'
                    size='lg'
                    className='flex-grow'
                    disabled={isSubmitting}
                    testId='sso-lookup-form-cancel-button'
                    onClick={onCancel}
                  >
                    {t('lookup-form.cancel.label')}
                  </Button>
                  <SubmitButton
                    size='lg'
                    className='flex-grow'
                    disabled={isSubmitting}
                    testId='sso-lookup-form-submit-button'
                  >
                    {t('lookup-form.submit.label')}
                  </SubmitButton>
                </div>
              </Form>
            </div>
          )
        }}
      </Formik>
    </CardContainer>
  )
}

export interface OrganizationData {
  state: {
    application: string
    applicationRedirectUrl: string
  }
  provider: {
    name: string
    audience: string
    connection: string
  }
  loginUrl: string
}

type FetchOrganizationResponse =
  | { error: string; data: null }
  | { error: null; data: OrganizationData }

const useLookupForm = () => {
  const { t } = useTranslation()
  const { captureException } = useSentry()

  const schema = useMemo(
    () =>
      object({
        organization: string()
          .required(t('lookup-form.schema.organization.required'))
          .min(2, t('lookup-form.schema.organization.min-length'))
          .max(255, t('lookup-form.schema.organization.max-length'))
      }),
    [t]
  )

  const fetchOrganization = async (
    organizationName: string
  ): Promise<FetchOrganizationResponse> => {
    const url = `${LOOKUP_ENDPOINT}/${organizationName}`
    try {
      const response = await fetch(url)

      if (response.ok) {
        const data: OrganizationData = await response.json()
        return { error: null, data }
      }

      return {
        data: null,
        error:
          response.status === 404
            ? t('lookup-form.organization.error-message.not-found')
            : t('lookup-form.organization.error-message.unknown')
      }
    } catch (e) {
      captureException(e as Error)

      return {
        error: t('lookup-form.organization.error-message.unknown'),
        data: null
      }
    }
  }

  return { t, schema, fetchOrganization }
}
