import React, { useMemo } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useIntlProps } from 'banquet-runtime-modules'
import { MarketingCarousel } from '@toasttab/buffet-patterns-marketing-carousel'
import { TranslationProvider } from '@local/translations'
import cx from 'classnames'
import { LookupForm, OrganizationData } from './LookupForm'

export const App = () => {
  const queryClient = useMemo(() => new QueryClient(), [])

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <TranslationProvider>
          <Container />
        </TranslationProvider>
      </QueryClientProvider>
    </div>
  )
}

const Container = () => {
  const intl = useIntlProps()

  const handleLookupCancel = () => {
    window.history.back()
  }

  const handleLookupSuccess = (organization: OrganizationData) => {
    window.location.assign(organization.loginUrl)
  }

  return (
    <div className={cx('flex h-screen w-screen')}>
      <div className={cx('w-3/5', 'hidden md:block')}>
        <MarketingCarousel
          locale={intl.language}
          useExternalReactQueryProvider
        />
      </div>
      <div
        className={cx(
          'w-full md:w-2/5',
          'flex flex-col justify-center items-center'
        )}
      >
        <LookupForm
          onSuccess={handleLookupSuccess}
          onCancel={handleLookupCancel}
        />
      </div>
    </div>
  )
}
